import { graphql, useStaticQuery } from 'gatsby'

import Col from 'react-bootstrap/Col'
import ImageWithCaption from '../elements/ImageWithCaption'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Section from '../layout/Section'
import theme from '../../theme'

const AttributesSection = () => {
  const data = useStaticQuery(graphql`
    query {
      cat1: file(relativePath: { eq: "misc/attributes/att-1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, quality: 100, placeholder: BLURRED)
        }
      }
      cat2: file(relativePath: { eq: "misc/attributes/att-2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, quality: 100, placeholder: BLURRED)
        }
      }
      cat3: file(relativePath: { eq: "misc/attributes/att-3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, quality: 100, placeholder: BLURRED)
        }
      }
      cat4: file(relativePath: { eq: "misc/attributes/att-4.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150, quality: 100, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <Section backgroundColor={theme.palette.darkGrey}>
      <Row>
        <Col md={3}>
          <ImageWithCaption
            image={data.cat1.childImageSharp}
            caption="Persönliche Beratung"
          />
        </Col>
        <Col md={3}>
          <ImageWithCaption
            image={data.cat2.childImageSharp}
            caption="Einfache Kommunikation"
          />
        </Col>
        <Col md={3}>
          <ImageWithCaption
            image={data.cat3.childImageSharp}
            caption="Kreative Ideen"
          />
        </Col>
        <Col md={3}>
          <ImageWithCaption
            image={data.cat4.childImageSharp}
            caption="Individuell Zugeschnitten"
          />
        </Col>
      </Row>
    </Section>
  )
}

export default AttributesSection
