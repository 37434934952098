import { StaticImage } from 'gatsby-plugin-image'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ButtonLink from '../components/elements/buttons/ButtonLink'
import CallToAction from '../components/elements/CallToAction'
import BaseLayout from '../components/layout/BaseLayout'
import Section from '../components/layout/Section'
import AttributesSection from '../components/sections/AttributesSection'
import makeSeoTitle from '../lib/utils/makeSeoTitle'
import React from 'react'

const PricingPage = () => (
  <BaseLayout>
    <Section>
      <Row>
        <Col md={6} className="vertical-center">
          <div>
            <h1>Lassen Sie uns über Geld reden!</h1>
            <p>Sie brauchen eine Preisspanne für Ihr Projekt?</p>

            <p>
              Das Problem ist, dass nicht jedes Projekt gleich ist.
              Beispielsweise Logos variieren wie alles andere in Komplexität,
              Größe und Umfang.
            </p>

            <p>
              Bei kleineren oder überschaubaren Projekten kann ich in der Regel
              einen Pauschalpreis vereinbaren. Bei größeren Projekten neige ich
              jedoch dazu, einen Stundensatz zu berechnen.
            </p>

            <p>
              Daher empfehle ich Ihnen, sich zuerst mit mir in Verbindung zu
              setzen und Ihr Projekt gemeinsam zu besprechen. Auf diese Weise
              kann ich Ihnen die beste Beratung für Ihr persönliches Anliegen
              bieten und mögliche Empfehlungen für die Umsetzung und Lösung
              Ihres Problems geben.
            </p>

            <ButtonLink variant="primary" to="/kontakt/">
              Kontakt
            </ButtonLink>
          </div>
        </Col>
        <Col md={6}>
          <StaticImage
            src="../assets/img/pages/pricing/cash.png"
            quality={100}
            placeholder="blurred"
            alt="Geldscheine und Münzen"
          />
        </Col>
      </Row>
    </Section>

    <AttributesSection />

    <Section>
      <Row>
        <Col md={6} className="horizontal-center">
          <StaticImage
            src="../assets/img/pages/pricing/low-budget.png"
            quality={100}
            placeholder="blurred"
            alt="Geringes Budget"
          />
        </Col>
        <Col md={6} className="vertical-center">
          <h1>Preiswerte Projekte</h1>
          <p>
            EisDesigns ist nicht nur für Unternehmen, sondern auch für
            Privatpersonen, die in der Regel weniger Geld ausgeben möchten. Für
            diese gibt es oft auch eine unkomplizierte und günstige Lösung, um
            Projekte oder Designs zu realisieren.
          </p>

          <p>
            Eine Vielzahl von bereits vorgefertigten Stockmaterialien bieten die
            Möglichkeit, preiswerte Designs für Ihr Projekt zu erwerben. Diese
            können dann bei mir auf Ihre individuellen Bedürfnisse angepasst
            werden.
          </p>
        </Col>
      </Row>
    </Section>

    <CallToAction buttonLink="/kontakt/" buttonText="Kontakt">
      Sie haben immer noch Fragen? Dann helfe ich Ihnen gerne in einem
      Beratungsgespräch weiter.
    </CallToAction>
  </BaseLayout>
)

export const Head = () => (
  <>
    <title>{makeSeoTitle('Preise')}</title>
    <meta
      name="description"
      content="Hier erfährst du wie dein Projekt aufgrund seiner Größe, Komplexität und Umfang preislich eingeschätzt wird. Kontaktiere mich für mehr Informationen!"
    />
  </>
)

export default PricingPage
