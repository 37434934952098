import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import React from 'react'
import { css } from '@emotion/react'

const imageWithCaptionStyle = css`
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-size: 1.2rem;
`

const ImageWithCaption = ({ image, caption }) => (
  <div css={imageWithCaptionStyle}>
    <GatsbyImage image={getImage(image)} alt={caption} />
    <div>{caption}</div>
  </div>
)

export default ImageWithCaption
